.App {
  text-align: center;
}

html {

  background: rgb(10,12,60);
  background: radial-gradient(circle, rgba(10,12,60,1) 0%, rgba(37,70,105,1) 100%);

}

#replay,
#forward{
  margin: 0 10px;
  transition: all .3s ease;
}

#replay:hover i{
  animation: animate_replay 1s ease;
}

#forward:hover i{
  animation: animate_forward 1s ease;
}

#forward i{
  transform: scaleX(-1);
}

#replay-content,
#forward-content{
  width: 40px;
  height: 40px;
  position: relative;
}

#replay-content span,
#forward-content span{
  height: 40px;
  width: 40px;
  position: absolute;
  top: 3px;
  left: 0;
  color: #607d8b !important;
  font-size: 11px;
  font-weight: 700;
}

@keyframes animate_replay{
    0%{
      transform: rotate(0deg) translateX(0) translateY(0);
    }
    15%{
      transform: rotate(10deg) translateX(0) translateY(0);
    }
    55%{
      transform: rotate(-35deg) translateX(-1.1px) translateY(-0.3px);
    }
    90%{
      transform: rotate(5deg) translateX(0) translateY(0);
    }
    100%{
      transform: rotate(0deg) translateX(0) translateY(0);
    }

}

@keyframes animate_forward{
  0%{
    transform: scaleX(-1) rotate(0deg) translateX(0) translateY(0);
  }
  15%{
    transform: scaleX(-1) rotate(10deg) translateX(0) translateY(0);
  }
  55%{
    transform: scaleX(-1) rotate(-35deg) translateX(-1.1px) translateY(-0.3px);
  }
  90%{
    transform: scaleX(-1) rotate(5deg) translateX(0) translateY(0);
  }
  100%{
    transform: scaleX(-1) rotate(0deg) translateX(0) translateY(0);
  }

}

/*#replay::before{
  content: '15';
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #607d8b !important;
  font-size: 10px;
  font-weight: bold;
  top: 2px;
  position: absolute;
  vertical-align: middle;
}*/